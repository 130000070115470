// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Styling
import { text } from './styles.module.css';

const NormalMessage = ({ transactionId }) => (
  <>
    <p className={text}>Tickets will be emailed to the email address provided.</p>
    <p className={text}>{`Transaction #${transactionId}`}</p>
  </>
);
NormalMessage.propTypes = {
  transactionId: PropTypes.string.isRequired
};

export default NormalMessage;
