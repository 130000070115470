import React from 'react';
import PaymentSuccess from 'buyFlow/components/PaymentSuccess';
import qs from 'qs';

export default ({ location }) => {
  const params = location.search && location.search.slice(1);
  const parsedParams = qs.parse(params);
  return (
    <PaymentSuccess {...parsedParams} />
  );
};
